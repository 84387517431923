import React, {Component} from "react";
import {Fade, Slide} from "react-reveal";
import{ init, sendForm} from 'emailjs-com';
import {configs} from "../configurations";
init(configs.USER_ID);

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state= {
            isSending: false
        }
    }
    render() {
        if (!this.props.data) return null;
        const message = this.props.data.contactmessage;

      const sendEmail = (e) => {
        e.preventDefault();
        this.setState({isSending: true});
        sendForm(configs.SERVICE_ID, configs.TEMPLATE_ID, e.target)
            .then((result) => {
              console.log(result.text);
                this.setState({isSending: false});
                alert('Thank you for submitting details');
                document.getElementById('contactForm').reset();
            }, (error) => {
              console.log(error.text);
              alert(error.text);
              this.setState({isSending: false});
            });
      }

        return (
            <section id="contact">
                <Fade bottom duration={1000}>
                    <div className="row section-head">
                        <div className="two columns header-col">
                            <h1>
                                <span>Get In Touch.</span>
                            </h1>
                        </div>

                        <div className="ten columns">
                            <p className="lead">{message}</p>
                        </div>
                    </div>
                </Fade>

                <div className="row">
                    <Slide left duration={1000}>
                        <div className="eight columns">
                            <form id="contactForm" name="contactForm" onSubmit={sendEmail}>
                                <fieldset>
                                    <div>
                                        <label htmlFor="from_name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="35"
                                            id="from_name"
                                            name="from_name"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="from_email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            defaultValue=""
                                            size="35"
                                            id="from_email"
                                            name="from_email"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="subject">Subject</label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="35"
                                            id="subject"
                                            name="subject"
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="message">
                                            Message <span className="required">*</span>
                                        </label>
                                        <textarea
                                            cols="50"
                                            rows="15"
                                            id="message"
                                            name="message"
                                            required
                                        />
                                    </div>

                                    <div>
                                        {/*TODO: include a reset secondary button*/}
                                        <button className="submit">Submit</button>
                                        { this.state.isSending? <span id="image-loader">
                                            <img alt="" src="images/loader.gif"/>
                                        </span>: null }
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </Slide>

                    {/*<Slide right duration={1000}>*/}
                    {/*    <aside className="four columns footer-widgets">*/}
                    {/*        <div className="widget widget_contact">*/}
                    {/*            <h4>Address and Phone</h4>*/}
                    {/*            <p className="address">*/}
                    {/*                {name}*/}
                    {/*                <br/>*/}
                    {/*                {city}, {country}*/}
                    {/*                <br/>*/}
                    {/*                <span>{phone}</span>*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </aside>*/}
                    {/*</Slide>*/}
                </div>
            </section>
        );
    }
}

export default Contact;
